import React from 'react';
import Copy from '@nib-components/copy';
import Card from '@nib/card';
import Modal from '@nib-components/modal';
import { SecondaryButton } from '@nib-components/button';

export interface DirectorProfileProps {
  profilePic: string;
  heading: string;
  subHeading: string;
  children: React.ReactNode;
}

export const DirectorProfile = ({
  profilePic,
  heading,
  subHeading,
  children,
}: DirectorProfileProps) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <Modal visible={isOpen} onClose={() => setOpen(false)} title={heading}>
        {children}
      </Modal>
      <Card image={profilePic} imageAlt={heading} padding={{ xs: 2, md: 4 }}>
        <Card.Content title={heading}>
          <Copy measure={false}>{subHeading}</Copy>
        </Card.Content>
        <Card.Footer>
          <SecondaryButton onClick={() => setOpen(true)}>Read More</SecondaryButton>
        </Card.Footer>
      </Card>
    </>
  );
};
